
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile, IUserProfileUpdate } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import { dispatchUpdateUserProfile } from "@/store/main/actions";

@Component
export default class UserProfileEdit extends Vue {
  public valid = true;
  public password1 = "";
  public password2 = "";

  get userProfile() {
    return readUserProfile(this.$store) as IUserProfile;
  }

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      updatedProfile.password = this.password1;
      await dispatchUpdateUserProfile(this.$store, updatedProfile);
      this.$router.push("/profile");
    }
  }
}
